import { Container, Row, Col, Divider } from '@geberit/gdds';

// styles
import styles from './intro-text.module.scss';

// types
import type { IntroTextProps } from './types';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { getClassName } from './utils';
import { classNameBuilder } from 'utils/classNameBuilder';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { useThemeName } from 'utils/hooks/use-theme';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useGdds } from 'utils/hooks/use-gdds';
import { useXy } from 'utils/hooks/use-xy';
import { emptyStringFallback } from 'utils/is-empty';

export function IntroText({
  className,
  title,
  subtitle,
  intro,
  text,
  position = 'left',
  contentIndex,
  contentLength,
  spacing,
  border,
  backgroundColor,
  titlePreviewId = '#st_title',
  subtitlePreviewId = '#st_subtitle',
  disableInEdit = false,
  isGdds,
  emphasize,
}: Readonly<IntroTextProps>) {
  const hasHeadline = Boolean(title || subtitle);
  const themeName = useThemeName();
  const isDefault = themeName === 'DEFAULT';
  const isNordics = useNord();
  const isGddsTheme = useGdds();
  const isXy = useXy();
  const sectionId = useSectionId(title, '', false, contentIndex);
  const colSize = [4, 8, isNordics ? 8 : 12];

  let maxContentWidth;
  if (isGddsTheme) {
    maxContentWidth = buildSize(gridSizes.gddsFullGrid);
  } else if (!isGdds) {
    maxContentWidth = '80rem';
  } else {
    maxContentWidth = buildSize(gridSizes.twoThird);
  }

  return (
    <div
      className={getClassName({
        hasHeadline,
        contentLength: contentLength ?? 0,
        contentIndex: contentIndex ?? 0,
        spacing,
        backgroundColor,
        isNordics: isNordics || isXy,
        isDefault,
        className,
      })}
      id={sectionId}
    >
      <Container maxContentWidth={maxContentWidth}>
        <Col size={colSize}>
          <div className={classNameBuilder(styles.row, styles[emptyStringFallback(position, 'left')])}>
            <Headline
              title={title}
              subtitle={subtitle}
              text={text}
              intro={intro}
              tag={Formats.h1}
              textClassName={classNameBuilder(styles.text, intro && styles.withMargin)}
              introTextClassName={styles.intro}
              emphasize={emphasize}
              className={classNameBuilder(!intro && !text && styles.noMarginBottom, styles[emptyStringFallback(position, 'left')])}
              {...(!disableInEdit && { titlePreviewId, subtitlePreviewId })}
              {...(!isXy && { isFlexItem: true })}
            />
          </div>
        </Col>
      </Container>
      {border !== 'disable-border' && (
        <Container maxContentWidth="78rem">
          <Row>
            <Col size={colSize} className={styles.border}>
              <Divider alignment="horizontal" />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
