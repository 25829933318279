'use client';

// types
import type { ItemProps } from 'components/ContentElementsGdds/hero-image/hero-image-gdds.types';

// components
import Layout from './Layout';
import { ContentArea } from 'components/ContentArea/ContentArea';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';

// utils
import { hasContentAreas, useContent } from 'components/Page/page-hooks';

type LandingProps = {
  slider: {
    items: ItemProps[];
  };
};

export function Landing() {
  const pageContent = useContent<{}, LandingProps>();

  if (!hasContentAreas(pageContent)) {
    return null;
  }
  const {
    breadcrumbs,
    metaData,
    contentAreas: { content },
    page: { slider },
  } = pageContent;

  const isTypeA = (slider.items?.length === 1) || !!slider.items?.find((item) => item.heroType === 'bgvideo');

  if (!contentExists(pageContent)) {
    return null;
  }

  return (
    <Layout metaData={metaData}>
      <HeroImageGdds
        wideLayout
        hideScrollIndicator
        disableAnimation={isTypeA}
        disableSliderControls={!isTypeA}
        {...slider}
      />
      <Breadcrumb items={breadcrumbs} />
      <ContentArea content={content} contentType="content" />
    </Layout>
  );
}
